/*
 * jQuery FlexSlider v2.5.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 * 
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
@font-face {
  font-family: 'flexslider-icon';
  src: url('fonts/flexslider-icon.eot');
  src: url('fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('fonts/flexslider-icon.woff') format('woff'), url('fonts/flexslider-icon.ttf') format('truetype'), url('fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover,
.flex-container a:focus,
.flex-slider a:focus {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0;
}
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
  width: 100%;
  display: block;
}
.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0 0 60px;
  background: #ffffff;
  border: 4px solid #ffffff;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
}
.flexslider .slides {
  zoom: 1;
}
.flexslider .slides img {
  height: auto;
}
.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  *height: 0;
}
.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.flex-direction-nav a:before {
  font-family: "flexslider-icon";
  font-size: 40px;
  display: inline-block;
  content: '\f001';
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}
.flex-direction-nav a.flex-next:before {
  content: '\f002';
}
.flex-direction-nav .flex-prev {
  left: -50px;
}
.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right;
}
.flexslider:hover .flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 10px;
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}
.flexslider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
  right: 10px;
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}
.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
}
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}
.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}
.flex-pauseplay a:hover {
  opacity: 1;
}
.flex-pauseplay a.flex-play:before {
  content: '\f003';
}
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}
.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}
.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}
.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}
.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}
/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}

/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

.owl-theme .owl-controls{margin-top:10px;text-align:center;-webkit-tap-highlight-color:transparent}.owl-theme .owl-controls .owl-nav [class*=owl-]{color:#fff;font-size:14px;margin:5px;padding:4px 7px;background:#d6d6d6;display:inline-block;cursor:pointer;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px}.owl-theme .owl-controls .owl-nav [class*=owl-]:hover{background:#869791;color:#fff;text-decoration:none}.owl-theme .owl-controls .owl-nav .disabled{opacity:.5;cursor:default}.owl-theme .owl-dots .owl-dot{display:inline-block;zoom:1;*display:inline}.owl-theme .owl-dots .owl-dot span{width:10px;height:10px;margin:5px 7px;background:#d6d6d6;display:block;-webkit-backface-visibility:visible;-webkit-transition:opacity 200ms ease;-moz-transition:opacity 200ms ease;-ms-transition:opacity 200ms ease;-o-transition:opacity 200ms ease;transition:opacity 200ms ease;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px}.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span{background:#869791}
/*
 * ---------------------------------------------------------------------------------------
 * All the media related queries
 * ---------------------------------------------------------------------------------------
 *
 * 1. Extra Small Mobile Devices (Minimum width of 320px and maximum width of 479px)
 * 2. Mobile Devices (Minimum width of 480px and maximum width of 767px)
 * 3. Tablet Devices (Minimum width of 768px and maximum width of 1023px)
 * 4. Older version of Desktops and/or Laptops (Minimum width of 1024px and Maximum width of 1199px)
 * 5. Newest version of desktops and/or laptops (Minimum width of 1200px and above)
 *
 */







/*
 * ---------------------------------------------------------------------------------------
 * All the styles related to extra small mobile devices
 * ---------------------------------------------------------------------------------------
 * 
 */
@media screen and (min-width: 1px) and (max-width: 320px) {
    .information-left {
        display: none;
    }

    .information-right {
        float: none;
        font-size: 12px;
        text-align: center;
    }

    .information-right li {
        display: block;
        width: 100%;
        padding: 1% 10%;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: #D2D5D6;
    }

    .information-right li:first-child {
        border-bottom: none;
    }

    .logoInformation #logo {
        height: 70%;
        line-height: 150px;
        text-align: center;
    }

    .logoInformation #logo .logoImage {
        display: inline-block;
        vertical-align: top;
        padding: 5% 0 !important;
        width: 67% !important;
    }

    .logoInformation #searchProduct {
        height: 70%;
        line-height: 30px;
        text-align: center;
        padding-bottom: 7.5%;
        margin-bottom: -25%;
    }

    .logoInformation #searchProduct .txtSearchProduct {
        width: 70%;
        border: 2px solid #E4E4E2;
        outline: none;
        border-radius: 3px;
        margin-top: -8em;
        display: inline-block;
        vertical-align: middle;
        background: #F5F7F8;
    }

    .imageZoomGallery {
        display: none !important;
    }

    .productCode {
        margin-bottom: 20px;
    }

    .productPrice {
        width: 100%;
        float: left;
        margin: 20px 0;
    }

    .btnCartPage {
        width: 100%;
        display: block;
        margin: 5% 0;
        font-size: 11px;
    }

    #loginModal .modal-body .btnLogin, #registerModal .modal-body .btnRegister {
        width: 100% !important;
        margin-bottom: 5% !important;
        margin-left: 0 !important;
    }

    .tagsListOnProductPage li {
        padding: 2% 2% !important;
    }

    #productVideoLink iframe {
        width: 100% !important;
        height: 100% !important;
        margin: 15px 0;
    }

    .allTags {
        width: 100% !important;
    }

    .footer .socialMediaIcons a {
        position: relative;
        display: inline-block;
        float: none;
        text-decoration: none;
    }

    .footer .socialMediaIcons a img {
        display: inline-block;
        width: 30px;
        height: 30px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        border: none;
    }

    .footer .closingDown {
        border-top: 1px solid #6e6e6e;
        margin-top: 15px;
    }

    .footer .closingDown .copyright {
        float: none;
        font-size: 11px;
        margin-top: 5px;
        text-align: center;
    }

    .footer .closingDown .designedBy {
        float: none;
        font-size: 11px;
        margin-top: 5px;
        text-align: center;
    }

    .m_xs_top_0 {margin-top: 0}
    .m_xs_top_5 {margin-top: 5px}
    .m_xs_top_10 {margin-top: 10px}
    .m_xs_top_15 {margin-top: 15px}
    .m_xs_top_20 {margin-top: 20px}
    .m_xs_top_25 {margin-top: 25px}
    .m_xs_top_30 {margin-top: 30px}
    .m_xs_top_50 {margin-top: 50px}

    .m_xs_right_0 {margin-right: 0}
    .m_xs_right_5 {margin-right: 5px}
    .m_xs_right_10 {margin-right: 10px}
    .m_xs_right_15 {margin-right: 15px}
    .m_xs_right_20 {margin-right: 20px}
    .m_xs_right_25 {margin-right: 25px}
    .m_xs_right_30 {margin-right: 30px}
    .m_xs_right_50 {margin-right: 50px}

    .m_xs_bottom_0 {margin-bottom: 0}
    .m_xs_bottom_5 {margin-bottom: 5px}
    .m_xs_bottom_10 {margin-bottom: 10px}
    .m_xs_bottom_15 {margin-bottom: 15px}
    .m_xs_bottom_20 {margin-bottom: 20px}
    .m_xs_bottom_25 {margin-bottom: 25px}
    .m_xs_bottom_30 {margin-bottom: 30px}
    .m_xs_bottom_50 {margin-bottom: 50px}

    .m_xs_left_0 {margin-left: 0}
    .m_xs_left_5 {margin-left: 5px}
    .m_xs_left_10 {margin-left: 10px}
    .m_xs_left_15 {margin-left: 15px}
    .m_xs_left_20 {margin-left: 20px}
    .m_xs_left_25 {margin-left: 25px}
    .m_xs_left_30 {margin-left: 30px}
    .m_xs_left_50 {margin-left: 50px}

    .p_xs_top_0 {padding-top: 0}
    .p_xs_top_5 {padding-top: 5px}
    .p_xs_top_10 {padding-top: 10px}
    .p_xs_top_15 {padding-top: 15px}
    .p_xs_top_20 {padding-top: 20px}
    .p_xs_top_25 {padding-top: 25px}
    .p_xs_top_30 {padding-top: 30px}
    .p_xs_top_50 {padding-top: 50px}

    .p_xs_right_0 {padding-right: 0}
    .p_xs_right_5 {padding-right: 5px}
    .p_xs_right_10 {padding-right: 10px}
    .p_xs_right_15 {padding-right: 15px}
    .p_xs_right_20 {padding-right: 20px}
    .p_xs_right_25 {padding-right: 25px}
    .p_xs_right_30 {padding-right: 30px}
    .p_xs_right_50 {padding-right: 50px}

    .p_xs_bottom_0 {padding-bottom: 0}
    .p_xs_bottom_5 {padding-bottom: 5px}
    .p_xs_bottom_10 {padding-bottom: 10px}
    .p_xs_bottom_15 {padding-bottom: 15px}
    .p_xs_bottom_20 {padding-bottom: 20px}
    .p_xs_bottom_25 {padding-bottom: 25px}
    .p_xs_bottom_30 {padding-bottom: 30px}
    .p_xs_bottom_50 {padding-bottom: 50px}

    .p_xs_left_0 {padding-left: 0}
    .p_xs_left_5 {padding-left: 5px}
    .p_xs_left_10 {padding-left: 10px}
    .p_xs_left_15 {padding-left: 15px}
    .p_xs_left_20 {padding-left: 20px}
    .p_xs_left_25 {padding-left: 25px}
    .p_xs_left_30 {padding-left: 30px}
    .p_xs_left_50 {padding-left: 50px}
}





/*
 * ---------------------------------------------------------------------------------------
 * All the styles related to extra small mobile devices
 * ---------------------------------------------------------------------------------------
 * 
 */
@media screen and (min-width: 321px) and (max-width: 479px) {
    body {
        overflow-x: hidden !important;
    }
    
    .information-left {
        display: none;
    }

    .information-right {
        float: none;
        font-size: 12px;
        text-align: center;
    }

    .information-right li {
        display: inline;
        width: 50%;
        padding: 0 5%;
        border-right: 1px solid #635F60;
        border-left: 1px solid #635F60;
    }

    .logoInformation #logo {
        height: 70%;
        line-height: 150px;
        text-align: center;
    }

    .logoInformation #logo .logoImage {
        display: inline-block;
        vertical-align: top;
        padding: 5% 0 !important;
        width: 50% !important;
    }

    .logoInformation #searchProduct {
        height: 70%;
        line-height: 30px;
        text-align: center;
        margin-bottom: -5%;
    }

    .logoInformation #searchProduct .txtSearchProduct {
        width: 70%;
        border: 2px solid #E4E4E2;
        outline: none;
        margin-top: -5em;
        border-radius: 3px;
        display: inline-block;
        vertical-align: middle;
        background: #F5F7F8;
    }

    .imageZoomGallery {
        display: none !important;
    }

    .productCode {
        margin-bottom: 20px;
    }

    .productPrice {
        width: 100%;
        float: left;
        margin: 20px 0;
    }

    .btnCartPage {
        width: 100%;
        display: block;
        margin: 5% 0;
        font-size: 11px;
    }

    #loginModal .modal-body a.forgotPassword {
        vertical-align: bottom;
        margin-left: 5%;
    }

    .tagsListOnProductPage li {
        padding: 1% 1% !important;
    }

    #productVideoLink iframe {
        width: 100% !important;
        height: 200px !important;
        margin: 15px 0;
    }

    .allTags {
        width: 50%;
    }

    .allTags:nth-child(odd) {
        float: left;
        height: 100px !important;

    }

    .allTags:nth-child(even) {
        float: right;
        height: 100px !important;
    }

    .footer .socialMediaIcons span {
        display: inline-block;
    }

    .footer .socialMediaIcons a {
        position: relative;
        display: inline-block;
        float: left;
        margin: 5px 5px;
        text-decoration: none;
    }

    .footer .socialMediaIcons a img {
        display: inline-block;
        width: 30px;
        height: 30px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        border: none;
    }

    .footer .closingDown {
        border-top: 1px solid #6e6e6e;
        margin-top: 15px;
    }

    .footer .closingDown .copyright {
        float: left;
        font-size: 11px;
        margin-top: 5px;
    }

    .footer .closingDown .designedBy {
        float: right;
        font-size: 11px;
        margin-top: 5px;
    }

    .m_xs_top_0 {margin-top: 0}
    .m_xs_top_5 {margin-top: 5px}
    .m_xs_top_10 {margin-top: 10px}
    .m_xs_top_15 {margin-top: 15px}
    .m_xs_top_20 {margin-top: 20px}
    .m_xs_top_25 {margin-top: 25px}
    .m_xs_top_30 {margin-top: 30px}
    .m_xs_top_50 {margin-top: 50px}

    .m_xs_right_0 {margin-right: 0}
    .m_xs_right_5 {margin-right: 5px}
    .m_xs_right_10 {margin-right: 10px}
    .m_xs_right_15 {margin-right: 15px}
    .m_xs_right_20 {margin-right: 20px}
    .m_xs_right_25 {margin-right: 25px}
    .m_xs_right_30 {margin-right: 30px}
    .m_xs_right_50 {margin-right: 50px}

    .m_xs_bottom_0 {margin-bottom: 0}
    .m_xs_bottom_5 {margin-bottom: 5px}
    .m_xs_bottom_10 {margin-bottom: 10px}
    .m_xs_bottom_15 {margin-bottom: 15px}
    .m_xs_bottom_20 {margin-bottom: 20px}
    .m_xs_bottom_25 {margin-bottom: 25px}
    .m_xs_bottom_30 {margin-bottom: 30px}
    .m_xs_bottom_50 {margin-bottom: 50px}

    .m_xs_left_0 {margin-left: 0}
    .m_xs_left_5 {margin-left: 5px}
    .m_xs_left_10 {margin-left: 10px}
    .m_xs_left_15 {margin-left: 15px}
    .m_xs_left_20 {margin-left: 20px}
    .m_xs_left_25 {margin-left: 25px}
    .m_xs_left_30 {margin-left: 30px}
    .m_xs_left_50 {margin-left: 50px}

    .p_xs_top_0 {padding-top: 0}
    .p_xs_top_5 {padding-top: 5px}
    .p_xs_top_10 {padding-top: 10px}
    .p_xs_top_15 {padding-top: 15px}
    .p_xs_top_20 {padding-top: 20px}
    .p_xs_top_25 {padding-top: 25px}
    .p_xs_top_30 {padding-top: 30px}
    .p_xs_top_50 {padding-top: 50px}

    .p_xs_right_0 {padding-right: 0}
    .p_xs_right_5 {padding-right: 5px}
    .p_xs_right_10 {padding-right: 10px}
    .p_xs_right_15 {padding-right: 15px}
    .p_xs_right_20 {padding-right: 20px}
    .p_xs_right_25 {padding-right: 25px}
    .p_xs_right_30 {padding-right: 30px}
    .p_xs_right_50 {padding-right: 50px}

    .p_xs_bottom_0 {padding-bottom: 0}
    .p_xs_bottom_5 {padding-bottom: 5px}
    .p_xs_bottom_10 {padding-bottom: 10px}
    .p_xs_bottom_15 {padding-bottom: 15px}
    .p_xs_bottom_20 {padding-bottom: 20px}
    .p_xs_bottom_25 {padding-bottom: 25px}
    .p_xs_bottom_30 {padding-bottom: 30px}
    .p_xs_bottom_50 {padding-bottom: 50px}

    .p_xs_left_0 {padding-left: 0}
    .p_xs_left_5 {padding-left: 5px}
    .p_xs_left_10 {padding-left: 10px}
    .p_xs_left_15 {padding-left: 15px}
    .p_xs_left_20 {padding-left: 20px}
    .p_xs_left_25 {padding-left: 25px}
    .p_xs_left_30 {padding-left: 30px}
    .p_xs_left_50 {padding-left: 50px}
}





/*
 * ---------------------------------------------------------------------------------------
 * All the styles related to newest version of mobile devices
 * ---------------------------------------------------------------------------------------
 * 
 */
@media screen and (min-width: 480px) and (max-width: 767px) {
    body {
        overflow-x: hidden !important;
    }

    .information-left {
        float: left;
        font-size: 13px;
    }

    .information-right {
        float: right;
        font-size: 13px;
    }

    .logoInformation #searchProduct {
        line-height: 30px;
        height: 30px;
    }

    .logoInformation #searchProduct .txtSearchProduct {
        width: 70%;
        border: 2px solid #E4E4E2;
        outline: none;
        border-radius: 3px;
        background: #F5F7F8;
    }

    #productVideoLink iframe {
        width: 70% !important;
        height: 200px !important;
        margin: 10px 0;
    }

    .tagsListOnProductPage li {
        padding: 1% 1% !important;
    }

    .imageZoomGallery {
        display: none !important;
    }

    .productCode {
        margin-bottom: 20px;
    }

    .productPrice {
        width: 100%;
        float: left;
    }

    .btns {
        margin-top: 7.5%;
        margin-bottom: 3.5%;
    }

    #loginModal .modal-body a.forgotPassword {
        vertical-align: bottom;
        margin-left: 5%;
    }

    .footer .socialMediaIcons span {
        display: inline-block;
    }

    .footer .socialMediaIcons a {
        position: relative;
        display: inline-block;
        float: left;
        margin: 5px 5px;
        text-decoration: none;
    }

    .footer .socialMediaIcons a img {
        display: inline-block;
        width: 30px;
        height: 30px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        border: none;
    }

    .footer .footerColumns.columnUsefulLinks {
        width: 50%;
        padding: 0 5px;
        border-right: 1px solid #6e6e6e;
        float: left;
    }

    .footer .footerColumns.columnCustomerSection {
        width: 50%;
        padding: 0 5px;
        float: left;
    }

    .footer .closingDown {
        border-top: 1px solid #6e6e6e;
        margin-top: 15px;
    }

    .footer .closingDown .copyright {
        float: left;
        font-size: 12px;
        margin-top: 5px;
    }

    .footer .closingDown .designedBy {
        float: right;
        font-size: 12px;
        margin-top: 5px;
    }

    .m_sm_top_0 {margin-top: 0}
    .m_sm_top_5 {margin-top: 5px}
    .m_sm_top_10 {margin-top: 10px}
    .m_sm_top_15 {margin-top: 15px}
    .m_sm_top_20 {margin-top: 20px}
    .m_sm_top_25 {margin-top: 25px}
    .m_sm_top_30 {margin-top: 30px}
    .m_sm_top_50 {margin-top: 50px}

    .m_sm_right_0 {margin-right: 0}
    .m_sm_right_5 {margin-right: 5px}
    .m_sm_right_10 {margin-right: 10px}
    .m_sm_right_15 {margin-right: 15px}
    .m_sm_right_20 {margin-right: 20px}
    .m_sm_right_25 {margin-right: 25px}
    .m_sm_right_30 {margin-right: 30px}
    .m_sm_right_50 {margin-right: 50px}

    .m_sm_bottom_0 {margin-bottom: 0}
    .m_sm_bottom_5 {margin-bottom: 5px}
    .m_sm_bottom_10 {margin-bottom: 10px}
    .m_sm_bottom_15 {margin-bottom: 15px}
    .m_sm_bottom_20 {margin-bottom: 20px}
    .m_sm_bottom_25 {margin-bottom: 25px}
    .m_sm_bottom_30 {margin-bottom: 30px}
    .m_sm_bottom_50 {margin-bottom: 50px}

    .m_sm_left_0 {margin-left: 0}
    .m_sm_left_5 {margin-left: 5px}
    .m_sm_left_10 {margin-left: 10px}
    .m_sm_left_15 {margin-left: 15px}
    .m_sm_left_20 {margin-left: 20px}
    .m_sm_left_25 {margin-left: 25px}
    .m_sm_left_30 {margin-left: 30px}
    .m_sm_left_50 {margin-left: 50px}

    .p_sm_top_0 {padding-top: 0}
    .p_sm_top_5 {padding-top: 5px}
    .p_sm_top_10 {padding-top: 10px}
    .p_sm_top_15 {padding-top: 15px}
    .p_sm_top_20 {padding-top: 20px}
    .p_sm_top_25 {padding-top: 25px}
    .p_sm_top_30 {padding-top: 30px}
    .p_sm_top_50 {padding-top: 50px}

    .p_sm_right_0 {padding-right: 0}
    .p_sm_right_5 {padding-right: 5px}
    .p_sm_right_10 {padding-right: 10px}
    .p_sm_right_15 {padding-right: 15px}
    .p_sm_right_20 {padding-right: 20px}
    .p_sm_right_25 {padding-right: 25px}
    .p_sm_right_30 {padding-right: 30px}
    .p_sm_right_50 {padding-right: 50px}

    .p_sm_bottom_0 {padding-bottom: 0}
    .p_sm_bottom_5 {padding-bottom: 5px}
    .p_sm_bottom_10 {padding-bottom: 10px}
    .p_sm_bottom_15 {padding-bottom: 15px}
    .p_sm_bottom_20 {padding-bottom: 20px}
    .p_sm_bottom_25 {padding-bottom: 25px}
    .p_sm_bottom_30 {padding-bottom: 30px}
    .p_sm_bottom_50 {padding-bottom: 50px}

    .p_sm_left_0 {padding-left: 0}
    .p_sm_left_5 {padding-left: 5px}
    .p_sm_left_10 {padding-left: 10px}
    .p_sm_left_15 {padding-left: 15px}
    .p_sm_left_20 {padding-left: 20px}
    .p_sm_left_25 {padding-left: 25px}
    .p_sm_left_30 {padding-left: 30px}
    .p_sm_left_50 {padding-left: 50px}
}





/*
 * ---------------------------------------------------------------------------------------
 * All the styles related to tablet devices
 * ---------------------------------------------------------------------------------------
 * 
 */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .information-left {
        float: left;
    }

    .information-right {
        float: right;
    }

    .logoInformation #searchProduct {
        line-height: 30px;
        height: 30px;
        padding: 7.5% 0;
    }

    .logoInformation #searchProduct .txtSearchProduct {
        float: right;
        width: 70%;
        border: 2px solid #E4E4E2;
        outline: none;
        border-radius: 3px;
        background: #F5F7F8;
    }

    .imageZoomGallery img {
        width: 20%;
        margin: 10px 3px;
    }

    .productCode {
        margin-bottom: 20px;
    }

    .productPrice {
        width: 100%;
        float: left;
    }

    .btnCartPage {
        width: 75%;
        margin: 5% auto;
        padding: 2%;
        display: block;
        font-size: 13px;
    }

    #loginModal .modal-body a.forgotPassword {
        vertical-align: bottom;
        margin-left: 5%;
    }

    .footer .socialMediaIcons a {
        position: relative;
        display: inline-block;
        float: none;
        text-decoration: none;
    }

    .footer .socialMediaIcons a img {
        display: inline-block;
        width: 30px;
        height: 30px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        border: none;
        margin-bottom: 0;
    }

    .footer .closingDown {
        border-top: 1px solid #6e6e6e;
        margin-top: 15px;
    }

    .footer .closingDown .copyright {
        float: left;
        font-size: 12px;
        margin-top: 5px;
    }

    .footer .closingDown .designedBy {
        float: right;
        font-size: 12px;
        margin-top: 5px;
    }

    .m_md_top_0 {margin-top: 0}
    .m_md_top_5 {margin-top: 5px}
    .m_md_top_10 {margin-top: 10px}
    .m_md_top_15 {margin-top: 15px}
    .m_md_top_20 {margin-top: 20px}
    .m_md_top_25 {margin-top: 25px}
    .m_md_top_30 {margin-top: 30px}
    .m_md_top_50 {margin-top: 50px}

    .m_md_right_0 {margin-right: 0}
    .m_md_right_5 {margin-right: 5px}
    .m_md_right_10 {margin-right: 10px}
    .m_md_right_15 {margin-right: 15px}
    .m_md_right_20 {margin-right: 20px}
    .m_md_right_25 {margin-right: 25px}
    .m_md_right_30 {margin-right: 30px}
    .m_md_right_50 {margin-right: 50px}

    .m_md_bottom_0 {margin-bottom: 0}
    .m_md_bottom_5 {margin-bottom: 5px}
    .m_md_bottom_10 {margin-bottom: 10px}
    .m_md_bottom_15 {margin-bottom: 15px}
    .m_md_bottom_20 {margin-bottom: 20px}
    .m_md_bottom_25 {margin-bottom: 25px}
    .m_md_bottom_30 {margin-bottom: 30px}
    .m_md_bottom_50 {margin-bottom: 50px}

    .m_md_left_0 {margin-left: 0}
    .m_md_left_5 {margin-left: 5px}
    .m_md_left_10 {margin-left: 10px}
    .m_md_left_15 {margin-left: 15px}
    .m_md_left_20 {margin-left: 20px}
    .m_md_left_25 {margin-left: 25px}
    .m_md_left_30 {margin-left: 30px}
    .m_md_left_50 {margin-left: 50px}

    .p_md_top_0 {padding-top: 0}
    .p_md_top_5 {padding-top: 5px}
    .p_md_top_10 {padding-top: 10px}
    .p_md_top_15 {padding-top: 15px}
    .p_md_top_20 {padding-top: 20px}
    .p_md_top_25 {padding-top: 25px}
    .p_md_top_30 {padding-top: 30px}
    .p_md_top_50 {padding-top: 50px}

    .p_md_right_0 {padding-right: 0}
    .p_md_right_5 {padding-right: 5px}
    .p_md_right_10 {padding-right: 10px}
    .p_md_right_15 {padding-right: 15px}
    .p_md_right_20 {padding-right: 20px}
    .p_md_right_25 {padding-right: 25px}
    .p_md_right_30 {padding-right: 30px}
    .p_md_right_50 {padding-right: 50px}

    .p_md_bottom_0 {padding-bottom: 0}
    .p_md_bottom_5 {padding-bottom: 5px}
    .p_md_bottom_10 {padding-bottom: 10px}
    .p_md_bottom_15 {padding-bottom: 15px}
    .p_md_bottom_20 {padding-bottom: 20px}
    .p_md_bottom_25 {padding-bottom: 25px}
    .p_md_bottom_30 {padding-bottom: 30px}
    .p_md_bottom_50 {padding-bottom: 50px}

    .p_md_left_0 {padding-left: 0}
    .p_md_left_5 {padding-left: 5px}
    .p_md_left_10 {padding-left: 10px}
    .p_md_left_15 {padding-left: 15px}
    .p_md_left_20 {padding-left: 20px}
    .p_md_left_25 {padding-left: 25px}
    .p_md_left_30 {padding-left: 30px}
    .p_md_left_50 {padding-left: 50px}
}





/*
 * ---------------------------------------------------------------------------------------
 * All the styles related to old version of desktop(s) and/or laptop(s) devices
 * ---------------------------------------------------------------------------------------
 * 
 */
@media screen and (min-width: 1024px) and (max-width: 1199px) {
    .information-left {
        float: left;
    }

    .information-right {
        float: right;
    }

    .logoInformation #searchProduct {
        line-height: 30px;
        height: 30px;
        padding: 7.5% 0;
    }

    .logoInformation #searchProduct .txtSearchProduct {
        float: right;
        width: 70%;
        border: 2px solid #E4E4E2;
        outline: none;
        border-radius: 3px;
        background: #F5F7F8;
    }

    .imageZoomGallery img {
        width: 20%;
        margin: 20px 5px;
    }

    .productCode {
        margin-bottom: 20px;
    }

    .productPrice {
        width: 100%;
        float: left;
    }

    .btnCartPage {
        width: 75%;
        margin: 5% auto;
        display: block;
        font-size: 13px;
    }

    #loginModal .modal-body a.forgotPassword {
        vertical-align: bottom;
        margin-left: 5%;
    }

    .footer .socialMediaIcons a {
        position: relative;
        display: inline-block;
        float: none;
        text-decoration: none;
    }

    .footer .socialMediaIcons a img {
        display: inline-block;
        width: 30px;
        height: 30px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        border: none;
        margin-bottom: 0;
    }

    .footer .closingDown {
        border-top: 1px solid #6e6e6e;
        margin-top: 15px;
    }

    .footer .closingDown .copyright {
        float: left;
        font-size: 12px;
        margin-top: 5px;
    }

    .footer .closingDown .designedBy {
        float: right;
        font-size: 12px;
        margin-top: 5px;
    }

    .m_lg_top_0 {margin-top: 0}
    .m_lg_top_5 {margin-top: 5px}
    .m_lg_top_10 {margin-top: 10px}
    .m_lg_top_15 {margin-top: 15px}
    .m_lg_top_20 {margin-top: 20px}
    .m_lg_top_25 {margin-top: 25px}
    .m_lg_top_30 {margin-top: 30px}
    .m_lg_top_50 {margin-top: 50px}

    .m_lg_right_0 {margin-right: 0}
    .m_lg_right_5 {margin-right: 5px}
    .m_lg_right_10 {margin-right: 10px}
    .m_lg_right_15 {margin-right: 15px}
    .m_lg_right_20 {margin-right: 20px}
    .m_lg_right_25 {margin-right: 25px}
    .m_lg_right_30 {margin-right: 30px}
    .m_lg_right_50 {margin-right: 50px}

    .m_lg_bottom_0 {margin-bottom: 0}
    .m_lg_bottom_5 {margin-bottom: 5px}
    .m_lg_bottom_10 {margin-bottom: 10px}
    .m_lg_bottom_15 {margin-bottom: 15px}
    .m_lg_bottom_20 {margin-bottom: 20px}
    .m_lg_bottom_25 {margin-bottom: 25px}
    .m_lg_bottom_30 {margin-bottom: 30px}
    .m_lg_bottom_50 {margin-bottom: 50px}

    .m_lg_left_0 {margin-left: 0}
    .m_lg_left_5 {margin-left: 5px}
    .m_lg_left_10 {margin-left: 10px}
    .m_lg_left_15 {margin-left: 15px}
    .m_lg_left_20 {margin-left: 20px}
    .m_lg_left_25 {margin-left: 25px}
    .m_lg_left_30 {margin-left: 30px}
    .m_lg_left_50 {margin-left: 50px}

    .p_lg_top_0 {padding-top: 0}
    .p_lg_top_5 {padding-top: 5px}
    .p_lg_top_10 {padding-top: 10px}
    .p_lg_top_15 {padding-top: 15px}
    .p_lg_top_20 {padding-top: 20px}
    .p_lg_top_25 {padding-top: 25px}
    .p_lg_top_30 {padding-top: 30px}
    .p_lg_top_50 {padding-top: 50px}

    .p_lg_right_0 {padding-right: 0}
    .p_lg_right_5 {padding-right: 5px}
    .p_lg_right_10 {padding-right: 10px}
    .p_lg_right_15 {padding-right: 15px}
    .p_lg_right_20 {padding-right: 20px}
    .p_lg_right_25 {padding-right: 25px}
    .p_lg_right_30 {padding-right: 30px}
    .p_lg_right_50 {padding-right: 50px}

    .p_lg_bottom_0 {padding-bottom: 0}
    .p_lg_bottom_5 {padding-bottom: 5px}
    .p_lg_bottom_10 {padding-bottom: 10px}
    .p_lg_bottom_15 {padding-bottom: 15px}
    .p_lg_bottom_20 {padding-bottom: 20px}
    .p_lg_bottom_25 {padding-bottom: 25px}
    .p_lg_bottom_30 {padding-bottom: 30px}
    .p_lg_bottom_50 {padding-bottom: 50px}

    .p_lg_left_0 {padding-left: 0}
    .p_lg_left_5 {padding-left: 5px}
    .p_lg_left_10 {padding-left: 10px}
    .p_lg_left_15 {padding-left: 15px}
    .p_lg_left_20 {padding-left: 20px}
    .p_lg_left_25 {padding-left: 25px}
    .p_lg_left_30 {padding-left: 30px}
    .p_lg_left_50 {padding-left: 50px}
}





/*
 * ---------------------------------------------------------------------------------------
 * All the styles related to the newest version of desktop(s) and/or laptop(s) devices
 * ---------------------------------------------------------------------------------------
 * 
 */
@media screen and (min-width: 1200px) {
    .information-left {
        float: left;
    }

    .information-right {
        float: right;
    }

    .logoInformation #logo {
        line-height: 50px;
        text-align: center;
    }

    .logoInformation #logo .logoImage {
        float: left;
        padding: 2% 0;
        margin-left: -3px;
    }

    .logoInformation #searchProduct {
        line-height: 30px;
        height: 30px;
        padding: 7.5% 0;
    }

    .logoInformation #searchProduct .txtSearchProduct {
        float: right;
        width: 50%;
        border: 2px solid #E4E4E2;
        outline: none;
        border-radius: 3px;
        background: #F5F7F8;
    }

    .footer .socialMediaIcons a {
        position: relative;
        display: inline-block;
        float: none;
        text-decoration: none;
    }

    .footer .socialMediaIcons a img {
        display: inline-block;
        width: 30px;
        height: 30px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        border: none;
        margin-bottom: 0;
    }

    .footer .closingDown {
        border-top: 1px solid #6e6e6e;
        margin-top: 15px;
    }

    .footer .closingDown .copyright {
        float: left;
        font-size: 12px;
        margin-top: 5px;
    }

    .footer .closingDown .designedBy {
        float: right;
        font-size: 12px;
        margin-top: 5px;
    }

    .m_top_0 {margin-top: 0}
    .m_top_5 {margin-top: 5px}
    .m_top_10 {margin-top: 10px}
    .m_top_15 {margin-top: 15px}
    .m_top_20 {margin-top: 20px}
    .m_top_25 {margin-top: 25px}
    .m_top_30 {margin-top: 30px}
    .m_top_50 {margin-top: 50px}

    .m_right_0 {margin-right: 0}
    .m_right_5 {margin-right: 5px}
    .m_right_10 {margin-right: 10px}
    .m_right_15 {margin-right: 15px}
    .m_right_20 {margin-right: 20px}
    .m_right_25 {margin-right: 25px}
    .m_right_30 {margin-right: 30px}
    .m_right_50 {margin-right: 50px}

    .m_bottom_0 {margin-bottom: 0}
    .m_bottom_5 {margin-bottom: 5px}
    .m_bottom_10 {margin-bottom: 10px}
    .m_bottom_15 {margin-bottom: 15px}
    .m_bottom_20 {margin-bottom: 20px}
    .m_bottom_25 {margin-bottom: 25px}
    .m_bottom_30 {margin-bottom: 30px}
    .m_bottom_50 {margin-bottom: 50px}

    .m_left_0 {margin-left: 0}
    .m_left_5 {margin-left: 5px}
    .m_left_10 {margin-left: 10px}
    .m_left_15 {margin-left: 15px}
    .m_left_20 {margin-left: 20px}
    .m_left_25 {margin-left: 25px}
    .m_left_30 {margin-left: 30px}
    .m_left_50 {margin-left: 50px}

    .p_top_0 {padding-top: 0}
    .p_top_5 {padding-top: 5px}
    .p_top_10 {padding-top: 10px}
    .p_top_15 {padding-top: 15px}
    .p_top_20 {padding-top: 20px}
    .p_top_25 {padding-top: 25px}
    .p_top_30 {padding-top: 30px}
    .p_top_50 {padding-top: 50px}

    .p_right_0 {padding-right: 0}
    .p_right_5 {padding-right: 5px}
    .p_right_10 {padding-right: 10px}
    .p_right_15 {padding-right: 15px}
    .p_right_20 {padding-right: 20px}
    .p_right_25 {padding-right: 25px}
    .p_right_30 {padding-right: 30px}
    .p_right_50 {padding-right: 50px}

    .p_bottom_0 {padding-bottom: 0}
    .p_bottom_5 {padding-bottom: 5px}
    .p_bottom_10 {padding-bottom: 10px}
    .p_bottom_15 {padding-bottom: 15px}
    .p_bottom_20 {padding-bottom: 20px}
    .p_bottom_25 {padding-bottom: 25px}
    .p_bottom_30 {padding-bottom: 30px}
    .p_bottom_50 {padding-bottom: 50px}

    .p_xs_left_0 {padding-left: 0}
    .p_xs_left_5 {padding-left: 5px}
    .p_xs_left_10 {padding-left: 10px}
    .p_xs_left_15 {padding-left: 15px}
    .p_xs_left_20 {padding-left: 20px}
    .p_xs_left_25 {padding-left: 25px}
    .p_xs_left_30 {padding-left: 30px}
    .p_xs_left_50 {padding-left: 50px}
}





/*
 * ---------------------------------------------------------------------------------------
 * All the styles related to the customized screen resolution.
 * ---------------------------------------------------------------------------------------
 * 
 */
@media screen and (min-width: 480px) and (max-width: 613px) {
    .information-left {
        display: none;
    }

    .information-right {
        float: none;
        font-size: 13px;
        text-align: center;
        padding-top: 5px 0;
    }

    .information-right li {
        display: inline;
        width: 50%;
        padding: 0 14%;
        border-right: 1px solid #635F60;
        border-left: 1px solid #635F60;
    }

    .logoInformation #logo {
        height: 70%;
        line-height: 150px;
        text-align: center;
    }

    .logoInformation #logo .logoImage {
        display: inline-block;
        vertical-align: top;
        padding: 5% 0 !important;
        width: 50% !important;
    }

    .logoInformation #searchProduct {
        line-height: 30px;
        height: 30px;
        padding-bottom: 10%;
        display: inline-block;
        width: 100% !important;
        margin-left: 15%;
        margin-bottom: 5%;
    }

    .logoInformation #searchProduct .txtSearchProduct {
        width: 70%;
        border: 2px solid #E4E4E2;
        outline: none;
        border-radius: 3px;
    }
}

@media screen and (min-width: 481px) and (max-width: 510px) {
    .information-right li {
        display: inline;
        width: 50%;
        padding: 0 12%;
        border-right: 1px solid #635F60;
        border-left: 1px solid #635F60;
    }
}

@media screen and (min-width: 613px) and (max-width: 767px) {
    .logoInformation #searchProduct {
        line-height: 30px;
        height: 30px;
        padding-bottom: 12.5%;
        padding-top: 1.5%;
        margin-top: -75px;
    }

    .logoInformation #searchProduct .txtSearchProduct {
        width: 50%;
        float: right !important;
        border: 2px solid #E4E4E2;
        outline: none;
        border-radius: 3px;
    }
}

@media screen and (min-width: 321px) and (max-width: 345px) {
    .footer .closingDown {
        margin-top: 15px;
    }

    .footer .closingDown .copyright {
        float: none;
        text-align: center;
        font-size: 10px;
    }

    .footer .closingDown .designedBy {
        float: none;
        text-align: center;
        font-size: 10px;
    }
}

@media screen and (min-width: 480px) and (max-width: 640px) {
    .btnCartPage {
        padding: 1% !important;
        font-size: 13px;
    }

    .btnContShop {
        width: 35% !important;
        float: left;
        display: block;
        margin: 5% 0%;
    }

    .btnEmptyCart {
        float: left;
        width: 25% !important;
        margin: 5% 2.5% !important;
    }

    .btnCheckout {
        float: right;
        width: 35% !important;
        display: block;
    }
}

@media screen and (min-width: 641px) and (max-width: 767px) {
    .btnCartPage {
        padding: 1% !important;
        font-size: 13px;
    }

    .btnContShop {
        width: 30% !important;
        float: left;
        margin: 5% 2.5%;
    }

    .btnEmptyCart {
        float: left;
        width: 20% !important;
        margin: 5% 2.5% 0 10% !important;
    }

    .btnCheckout {
        float: right;
        width: 30% !important;
    }
}
/*# sourceMappingURL=app.css.map */